import React, { MutableRefObject, useCallback, useState } from 'react';

import { BilingualText } from 'types/BilingualText';

import * as Styled from './IntroScreen.styles';

export interface IntroScreenCMSProps {
  animate?: any;
  logoText: string;
  title: BilingualText;
  enterButtonLabel: string;
  soundPrompt: BilingualText;
  soundLabel: string;
  soundLabelOn: string;
  soundLabelOff: string;
}

export interface Props extends IntroScreenCMSProps {
  isLoaded: boolean;
  playCanvasFireRef: MutableRefObject<(eventName: string, ...args) => void>;
  onStart?: (isSoundOn: boolean) => void;
}

const IntroScreen: React.FC<Props> = ({
  animate,
  isLoaded,
  playCanvasFireRef,
  onStart,
  logoText,
  title,
  enterButtonLabel,
  soundPrompt,
  soundLabel,
  soundLabelOn,
  soundLabelOff,
  ...rest
}: Props) => {
  const [isSoundOn, setIsSoundOn] = useState(true);

  const onEnterClick = useCallback(() => {
    playCanvasFireRef.current?.('react:click:enter', isSoundOn);
    onStart?.(isSoundOn);
  }, [onStart, isSoundOn, playCanvasFireRef]);

  const onSoundChange = useCallback((value: boolean) => {
    setIsSoundOn(value);
  }, []);

  return (
    <Styled.Wrapper animate={animate} {...rest}>
      <Styled.Background />
      <Styled.BackgroundGradient />
      <Styled.ContentWrapper>
        <Styled.Logo text={logoText} />
        <Styled.Main>
          <Styled.Subtitle>{title.textCn}</Styled.Subtitle>
          <Styled.Title>{title.textEn}</Styled.Title>
          <Styled.EnterButtonWrapper>
            {isLoaded ? (
              <Styled.EnterButton onClick={onEnterClick}>
                {enterButtonLabel}
              </Styled.EnterButton>
            ) : (
              <Styled.LoaderWrapper>
                <Styled.Loader />
              </Styled.LoaderWrapper>
            )}
          </Styled.EnterButtonWrapper>
        </Styled.Main>
        <Styled.BottomWrapper>
          <Styled.SoundPrompt>
            {soundPrompt.textCn}
            <br />
            {soundPrompt.textEn}
          </Styled.SoundPrompt>
          <Styled.Toggle
            label={soundLabel}
            valueOnLabel={soundLabelOn}
            valueOffLabel={soundLabelOff}
            selected={isSoundOn}
            onChange={onSoundChange}
          />
        </Styled.BottomWrapper>
      </Styled.ContentWrapper>
    </Styled.Wrapper>
  );
};

export default IntroScreen;
