import styled from 'styled-components';

export default styled.div`
  position: absolute;
  z-index: 1000;
  width: 50vw;
  height: 50vh;
  top: 6vh;
  left: 50%;
  transform: translateX(-50%);
  touch-action: none;
`;
