import React from 'react';

import * as Styled from './Button.styles';

export interface Props {
  children?: React.ReactNode;
  onClick?: () => void;
}

const Button: React.FC<Props> = ({ children, ...rest }: Props) => {
  return (
    <Styled.Wrapper {...rest}>
      {React.Children.toArray(children)}
    </Styled.Wrapper>
  );
};

export default Button;
