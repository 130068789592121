import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  top: -3px;
  right: -55px;
  height: 35px;
  width: 35px;
  z-index: 2000;
  touch-action: none;
  background-color: #ffffff;
  border-radius: 50%;
`;

const CrossSvg = () => {
  return (
    <svg
      version="1.0"
      viewBox="0 0 301.000000 301.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,301.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path d="M885 2120 c-19 -21 -16 -24 275 -315 l295 -295 -295 -295 -295 -295 23 -22 22 -23 295 295 295 295 295 -295 295 -295 22 23 23 22 -295 295 -295 295 295 295 295 295 -23 22 -22 23 -295 -295 -295 -295 -293 293 c-160 160 -295 292 -298 292 -3 0 -14 -9 -24 -20z" />
      </g>
    </svg>
  );
};

const StyledCross = styled(CrossSvg)`
  width: 40px;
  height: 40px;
`;

interface Props {
  captureScreenshot: () => void;
}

const CloseButton = ({ captureScreenshot }: Props) => {
  return (
    <Wrapper onClick={captureScreenshot}>
      <StyledCross />
    </Wrapper>
  );
};

export default CloseButton;
