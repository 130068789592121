import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Label = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 30px;
  letter-spacing: 0.4em;
  text-transform: uppercase;
  margin-right: 13px;
  color: #fff;
`;

export interface BaseProps {
  isSelected: boolean;
}
export const Base = styled(motion.div).attrs(({ isSelected }: BaseProps) => ({
  animate: {
    backgroundColor: isSelected ? '#000' : '#fff',
  },
}))<BaseProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0 5px;
  height: 30px;
  align-items: center;
  border-radius: 15px;
`;

export interface ValueLabelProps {
  isOn?: boolean;
  hidden?: boolean;
}
export const ValueLabel = styled.p<ValueLabelProps>`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 30px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #000;
  margin: 0 3px;
  transition-property: opacity, visibility;
  transition-duration: 0.3s;
  transition-timing-function: ease;

  &:first-of-type {
    margin-left: 6px;
  }

  &:last-of-type {
    margin-right: 6px;
  }

  ${({ isOn }) =>
    isOn &&
    css`
      color: #fff;
    `}

  ${({ hidden }) =>
    hidden &&
    css`
      display: block;
      opacity: 0;
      visibility: hidden;
    `}
`;

export interface DotProps {
  isSelected: boolean;
}
export const Dot = styled(motion.div).attrs(({ isSelected }: DotProps) => ({
  animate: {
    left: isSelected ? 'auto' : '1px',
    right: isSelected ? '1px' : 'auto',
    backgroundColor: isSelected ? '#fff' : '#000',
  },
}))<DotProps>`
  position: absolute;
  width: 28px;
  height: 28px;
  border-radius: 50%;
`;
