import React from 'react';

import * as Styled from './Loader.styles';

export interface Props {}

const Loader: React.FC<Props> = ({ ...rest }: Props) => {
  return <Styled.Wrapper {...rest}></Styled.Wrapper>;
};

export default Loader;
