import { motion } from 'framer-motion';
import { Router } from 'next/router';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { IntroScreenCMSProps } from 'components/IntroScreen/IntroScreen';
import { CopyStoreType } from 'store/copy.types';
import Head from 'u9/components/Head/Head';
import { pageMotionProps } from 'utils/styles/animations';

import * as Styled from './IndexPage.styles';

interface IndexPageProps extends IntroScreenCMSProps {
  head: CopyStoreType['copy']['head'];
  router: Router;
  rotateScreen: {
    message: {
      textEn: string;
      textCn: string;
    };
  };
  spaces: {
    name: string;
    info: {
      name: {
        textEn: string;
        textCn: string;
      };
      description: {
        textEn: string;
        textCn: string;
      };
    };
  }[];
}

const IndexPage: React.FunctionComponent<IndexPageProps> = ({
  head,
  rotateScreen,
  spaces,
}) => {
  const playCanvasFireRef = useRef<(eventName: string, ...args) => void>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [, setLoadingProgress] = useState(0);
  const [, setIsStarted] = useState(true);
  const [, setIsStartComplete] = useState(true);

  const onLoadProgress = (progress: number) => {
    setLoadingProgress(progress);
  };

  const onLoadComplete = () => {
    setIsLoading(false);
  };

  const onPlayCanvasEvent = useCallback(
    (eventName: string) => {
      switch (eventName) {
        case 'AppManager:ready':
          playCanvasFireRef.current?.('react:copy:set', { rotateScreen });
          playCanvasFireRef.current?.('react:spaces:set', spaces);
          setIsLoading(false);
          break;
      }
    },
    [rotateScreen]
  );

  const onStart = useCallback((isSoundOn: boolean) => {
    console.log('-- start', isSoundOn);
    setIsLoading(false);
    setIsStarted(true);
    setTimeout(() => setIsStartComplete(true), 2000);
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setIsLoading(false);
        playCanvasFireRef.current?.('react:click:enter', true);
        onStart?.(true);
      }, 1000);
    }
  }, [onStart, isLoading]);

  return (
    <>
      <Head {...head} />
      <motion.div {...pageMotionProps}>
        <Styled.Wrapper>
          {isLoading && (
            <Styled.LoaderWrapper>
              <Styled.Loader />
            </Styled.LoaderWrapper>
          )}
          <Styled.PlayCanvasProject
            path="/playcanvas/TKL"
            disableInput={isLoading}
            refFire={playCanvasFireRef}
            onLoadProgress={onLoadProgress}
            onLoadComplete={onLoadComplete}
            onEvent={onPlayCanvasEvent}
          />
          {/* {!isStartComplete && (
            <Styled.IntroScreen
              isLoaded={!isLoading}
              animate={isStarted ? 'hidden' : undefined}
              playCanvasFireRef={playCanvasFireRef}
              onStart={onStart}
              {...rest}
            />
          )} */}
        </Styled.Wrapper>
      </motion.div>
    </>
  );
};

export default IndexPage;
