import { motion } from 'framer-motion';
import styled from 'styled-components';

import logoImg from './assets/logo.svg';

export const Wrapper = styled(motion.div)``;

export const LogoImg = styled.div`
  width: 104px;
  height: 40px;
  background-image: url(${logoImg});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

export const Text = styled.h1`
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.4em;
  color: #fff;
  margin-top: 14px;
`;
