let isInitialized = false;

const loadScript = (path: string) => {
  return new Promise(resolve => {
    const script = document.createElement('script');
    script.src = path;
    script.onload = resolve;
    document.head.appendChild(script);
  });
};

export const init = async (projectPath: string) => {
  if (!projectPath) {
    throw new Error('projectPath needs to be specified');
  }

  if (isInitialized) {
    return;
  }

  const pPath = projectPath.endsWith('/')
    ? projectPath.substring(0, projectPath.length - 1)
    : projectPath;
  await loadScript(`${pPath}/playcanvas-stable.min.js`);
  await loadScript(`${pPath}/__settings__.js`);
  (window as any).ASSET_PREFIX = `${pPath}/`;
  await loadScript(`${pPath}/__modules__.js`);
  isInitialized = true;
};

export const createInputDevices = canvas => {
  const w = window as any;
  const pc = w.pc;
  const INPUT_SETTINGS = w.INPUT_SETTINGS;

  const devices = {
    elementInput: new w.pc.ElementInput(canvas, {
      useMouse: w.INPUT_SETTINGS.useMouse,
      useTouch: w.INPUT_SETTINGS.useTouch,
    }),
    keyboard: INPUT_SETTINGS.useKeyboard ? new pc.Keyboard(window) : null,
    mouse: INPUT_SETTINGS.useMouse ? new pc.Mouse(canvas) : null,
    gamepads: INPUT_SETTINGS.useGamepads ? new pc.GamePads() : null,
    touch:
      INPUT_SETTINGS.useTouch && pc.platform.touch
        ? new pc.TouchDevice(canvas)
        : null,
  };

  return devices;
};
