import { motion } from 'framer-motion';
import styled from 'styled-components';

import ButtonBase from 'components/Button';
import LoaderBase from 'components/Loader';
import LogoBase from 'components/Logo';
import ToggleBase from 'components/Toggle';

import backgroundImg from './assets/background.png';

const defaultHideTransition = {
  variants: {
    hidden: {
      opacity: 0,
    },
  },
  transition: {
    type: 'spring',
    stiffness: 80,
    damping: 10,
  },
};

export const Wrapper = styled(motion.section).attrs({
  key: 'IntroScreen',
  variants: {
    hidden: {
      opacity: 0,
      pointerEvents: 'none',
    },
  },
  transition: {
    type: 'spring',
    stiffness: 80,
    damping: 30,
    delay: 0.5,
    staggerChildren: 0.05,
  },
})`
  background-color: #000;
  color: #fff;
`;

export const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${backgroundImg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const BackgroundGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 0, 0, 0) 100%
  );
`;

export const ContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 50px 42px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (orientation: landscape) {
    padding: 20px 42px;
  }
`;

export const Logo = styled(LogoBase).attrs(defaultHideTransition)`
  position: relative;
  flex: 1;
`;

export const Main = styled.main`
  text-align: center;
  flex: 1;
`;

export const Subtitle = styled(motion.h2).attrs(defaultHideTransition)`
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.4em;
  position: relative;
`;

export const Title = styled(motion.h1).attrs(defaultHideTransition)`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  letter-spacing: 0.4em;
  position: relative;
  text-align: center;
  margin-top: 14px;
  text-transform: uppercase;
`;

export const EnterButtonWrapper = styled(motion.div).attrs(
  defaultHideTransition
)`
  position: relative;
  height: 50px;
  margin-top: 60px;

  @media screen and (orientation: landscape) {
    margin: 20px 0;
  }
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Loader = styled(LoaderBase)``;

export const EnterButton = styled(ButtonBase)`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
`;

export const BottomWrapper = styled(motion.div).attrs(defaultHideTransition)`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  max-width: 250px;
  align-items: center;
  justify-content: flex-end;
`;

export const SoundPrompt = styled.p`
  position: relative;
  font-family: 'Roboto';
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.4em;
  text-transform: uppercase;
`;

export const Toggle = styled(ToggleBase)`
  margin-top: 29px;

  @media screen and (orientation: landscape) {
    margin-top: 10px;
  }
`;
