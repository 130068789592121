import styled from 'styled-components';

import IntroScreenBase from 'components/IntroScreen';
import LoaderBase from 'components/Loader';
import PlayCanvasProjectBase from 'components/PlayCanvasProject';

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

export const PlayCanvasProject = styled(PlayCanvasProjectBase)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const IntroScreen = styled(IntroScreenBase)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
`;

export const Loader = styled(LoaderBase)``;
