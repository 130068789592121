import React, { useCallback } from 'react';

import * as Styled from './Toggle.styles';

export interface Props {
  label: string;
  valueOffLabel: string;
  valueOnLabel: string;
  selected: boolean;
  onChange?: (value: boolean) => void;
}

const Toggle: React.FC<Props> = ({
  label,
  valueOffLabel,
  valueOnLabel,
  selected,
  onChange,
  ...rest
}: Props) => {
  const toggle = useCallback(() => {
    onChange?.(!selected);
  }, [selected, onChange]);

  return (
    <Styled.Wrapper {...rest}>
      <Styled.Label>{label}</Styled.Label>
      <Styled.Base isSelected={selected} onClick={toggle}>
        <Styled.ValueLabel isOn hidden={!selected}>
          {valueOnLabel}
        </Styled.ValueLabel>
        <Styled.ValueLabel hidden={selected}>{valueOffLabel}</Styled.ValueLabel>
        <Styled.Dot isSelected={selected} />
      </Styled.Base>
    </Styled.Wrapper>
  );
};

export default Toggle;
