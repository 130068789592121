import mergeImages from 'merge-images';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';

const resizeImage = require('resize-image');

import Blur from './styled/Blur';
import BlurOverlay from './styled/BlurOverlay';
import CloseButton from './styled/CloseButton';
import OverlayWrapper from './styled/OverlayWrapper';
import SaveImageCopy from './styled/SaveImageCopy';
import SharedScreenshot from './styled/SharedScreenshot';
import Wrapper from './styled/Wrapper';
import { createInputDevices, init } from './utils';

export interface PlayCanvasProjectProps {
  path: string;
  disableInput?: boolean;
  refFire?: MutableRefObject<((eventName: string, ...args) => void) | null>;
  onError?: (code: number, message: string) => void;
  onEvent?: (eventName: string, ...args) => void;
  onLoadProgress?: (progress: number) => void;
  onLoadComplete?: () => void;
}

interface OverlayDimensions {
  w?: number;
  h?: number;
}

const PlayCanvasProject: React.FC<PlayCanvasProjectProps> = ({
  path,
  disableInput = false,
  refFire,
  onError,
  onEvent,
  onLoadProgress,
  onLoadComplete,
  ...restProps
}) => {
  const [, setIsLandscape] = useState<boolean>(false);
  const [isScreenCaptured, setIsScreenCaptured] = useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isAppInitialized, setIsAppInitialized] = useState(false);
  const [screenShootUrl, setScreenShootUrl] = useState<string>('');
  const wrapperRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const appRef = useRef(null);

  useEffect(() => {
    const asyncEffect = async () => {
      await init(path);
      setIsInitialized(true);
    };
    asyncEffect();

    return () => {
      window.removeEventListener('resize', reflow, false);
      window.removeEventListener('orientationchange', reflow, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isInitialized || !wrapperRef.current) {
      return;
    }

    const w = window as any;
    const pc = w.pc;

    // Init canvas
    const canvas = document.createElement('canvas');
    canvas.setAttribute('id', 'PlayCanvas');
    canvas.setAttribute('tabindex', '0');
    canvas.onselectstart = function () {
      return false;
    };
    wrapperRef.current.appendChild(canvas);
    canvasRef.current = canvas;

    // Init input devices
    const devices = createInputDevices(canvas);

    // Init Application
    try {
      initApplication(canvas, devices);
    } catch (e) {
      if (e instanceof pc.UnsupportedBrowserError) {
        onError &&
          onError(1, 'This page requires a browser that supports WebGL.');
      } else if (e instanceof pc.ContextCreationError) {
        onError &&
          onError(2, "It doesn't appear your computer can support WebGL.<br/>");
      } else {
        onError && onError(3, 'Could not initialize application. Error: ' + e);
      }

      return;
    }

    if (w.PRELOAD_MODULES.length > 0) {
      w.loadModules(w.PRELOAD_MODULES, w.ASSET_PREFIX, configure);
    } else {
      configure();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, wrapperRef.current]);

  const getImageDimensions = file => {
    return new Promise(resolved => {
      const i = new Image();
      i.onload = () => {
        resolved({ w: i.width, h: i.height });
      };
      i.src = file;
    });
  };

  const compressImage = (src, newX, newY) => {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const elem = document.createElement('canvas');
        elem.width = newX;
        elem.height = newY;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, newX, newY);
        const data = ctx.canvas.toDataURL();
        res(data);
      };
      img.onerror = error => rej(error);
    });
  };

  const onAppScreenshotEvent = data => {
    const fullQuality = canvasRef.current.toDataURL('image/jpeg', 1.0);
    const overlayImage = new Image();
    overlayImage.onload = async () => {
      const overlayImageLandscape = resizeImage.resize(
        overlayImage,
        data.width,
        data.height
      );
      const overlayDimensions: OverlayDimensions = await getImageDimensions(
        overlayImageLandscape
      );
      const screenShotAsImg = new Image();
      screenShotAsImg.src = fullQuality;
      compressImage(fullQuality, overlayDimensions.w, overlayDimensions.h).then(
        compressed => {
          mergeImages([compressed, overlayImageLandscape]).then(b64 =>
            setScreenShootUrl(b64)
          );
          setIsScreenCaptured(true);
        }
      );
    };
    if (data.width > data.height) setIsLandscape(true);
    overlayImage.src =
      data.width > data.height
        ? './TKL_Frame_Landscape.png'
        : './TKL_Frame_Portrait.png';
  };

  useEffect(() => {
    if (!appRef.current) {
      return () => null;
    }

    appRef.current.on('preload:progress', onAppLoadProgress);
    appRef.current.on('start', onAppStart);
    appRef.current.on('react', onAppEvent);
    appRef.current.on('screenshot:captured', onAppScreenshotEvent);

    return () => {
      appRef.current.off('react', onAppEvent);
      appRef.current.off('start', onAppStart);
      appRef.current.off('react', onAppEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAppInitialized]);

  const onCloseScreenshotClick = () => {
    appRef.current.fire('screenshot:complete');
    setIsScreenCaptured(false);
  };

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    appRef.current.elementInput.enabled = !disableInput;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableInput, canvasRef.current]);

  const initApplication = (canvas: HTMLCanvasElement, devices: any) => {
    const w = window as any;
    const pc = w.pc;

    const app = new pc.Application(canvas, {
      elementInput: devices.elementInput,
      keyboard: devices.keyboard,
      mouse: devices.mouse,
      gamepads: devices.gamepads,
      touch: devices.touch,
      graphicsDeviceOptions: w.CONTEXT_OPTIONS,
      assetPrefix: w.ASSET_PREFIX || '',
      scriptPrefix: w.SCRIPT_PREFIX || '',
      scriptsOrder: w.SCRIPTS || [],
    });
    appRef.current = app;
    refFire.current = fire;
    setIsAppInitialized(true);
  };

  const configure = () => {
    const app = appRef.current;
    const w = window as any;

    app.configure(`${w.ASSET_PREFIX}${w.CONFIG_FILENAME}`, err => {
      if (err) {
        console.error(err);
      }

      // do the first reflow after a timeout because of
      // iOS showing a squished iframe sometimes
      setTimeout(() => {
        reflow();

        window.addEventListener('resize', reflow, false);
        window.addEventListener('orientationchange', reflow, false);

        app.preload(err => {
          if (err) {
            console.error(err);
          }

          app.loadScene(w.SCENE_PATH, err => {
            if (err) {
              console.error(err);
            }

            app.start();
          });
        });
      });
    });
  };

  const reflow = () => {
    const app = appRef.current;
    const canvas = canvasRef.current;
    const w = window as any;
    const pc = w.pc;

    app.resizeCanvas(canvas.width, canvas.height);
    canvas.style.width = window.innerWidth + 'px';
    canvas.style.height = window.innerHeight + 'px';
    canvas.style.marginTop = '';

    const fillMode = app._fillMode;

    if (fillMode === pc.FILLMODE_NONE || fillMode === pc.FILLMODE_KEEP_ASPECT) {
      if (
        (fillMode === pc.FILLMODE_NONE &&
          canvas.clientHeight < window.innerHeight) ||
        canvas.clientWidth / canvas.clientHeight >=
          window.innerWidth / window.innerHeight
      ) {
        canvas.style.marginTop =
          Math.floor((window.innerHeight - canvas.clientHeight) / 2) + 'px';
      } else {
        canvas.style.marginTop = '';
      }
    }
  };

  const fire = (eventName: string, ...args) => {
    if (!appRef.current) {
      return false;
    }
    appRef.current.fire(eventName, ...args);
    return true;
  };

  const onAppLoadProgress = (progress: number) => {
    onLoadProgress && onLoadProgress(progress);
  };

  const onAppStart = () => {
    onLoadComplete && onLoadComplete();
  };

  const onAppEvent = (eventName: string, ...args) => {
    onEvent && onEvent(eventName, ...args);
  };

  return (
    <>
      {isScreenCaptured && screenShootUrl && (
        <BlurOverlay>
          <Blur />
          <OverlayWrapper>
            <SaveImageCopy>
              长按上图保存 <br /> Long press to save image
            </SaveImageCopy>
            <CloseButton captureScreenshot={onCloseScreenshotClick} />
            <SharedScreenshot src={screenShootUrl} />
          </OverlayWrapper>
        </BlurOverlay>
      )}
      <Wrapper {...restProps} ref={wrapperRef} isDisabled={isScreenCaptured} />
    </>
  );
};

export default PlayCanvasProject;
