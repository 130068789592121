import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';

import loaderImg from './assets/loader.svg';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Wrapper = styled(motion.button).attrs({
  key: 'Loader',
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: {
    type: 'spring',
    stiffness: 30,
    damping: 10,
  },
})`
  width: 30px;
  height: 30px;
  background-image: url(${loaderImg});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  animation: ${spin} 1.4s infinite linear;
`;
