import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Wrapper = styled(motion.div).attrs({
  key: 'Button',
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: {
    type: 'spring',
    stiffness: 30,
    damping: 10,
  },
})`
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.4em;
  color: white;
  background-color: #000;
  padding: 16px 45px;
  border: 1px solid rgba(255, 255, 255, 0.4);
`;
