import React from 'react';

import * as Styled from './Logo.styles';

export interface Props {
  text: string;
}

const Logo: React.FC<Props> = ({ text, ...rest }: Props) => {
  return (
    <Styled.Wrapper {...rest}>
      <Styled.LogoImg />
      <Styled.Text>{text}</Styled.Text>
    </Styled.Wrapper>
  );
};

export default Logo;
