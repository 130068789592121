import styled from 'styled-components';

export default styled.p`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -80px;
  text-align: center;
  color: #ffffff;
  font-family: 'Dejavu';
`;
