import styled, { css } from 'styled-components';

interface Props {
  isDisabled?: boolean;
}

export default styled.div<Props>`
  canvas {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.fill-mode-NONE {
      margin: auto;
    }

    &.fill-mode-KEEP_ASPECT {
      width: 100%;
      height: auto;
      margin: 0;
    }

    &.fill-mode-FILL_WINDOW {
      width: 100%;
      height: 100%;
      margin: 0;
    }

    &.disabled {
      pointer-events: none;
    }

    &:focus {
      outline: none;
    }

    ${({ isDisabled }) =>
      isDisabled &&
      css`
        pointer-events: none;
      `}
  }
`;
